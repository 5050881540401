import { keyExists } from "../common/commonFunctions";

// Type
const inputTypes = {
  text: "Text Input",
  number: "Number Input",
  picture: "Photograph Upload",
  document: "Document Upload",
  date: "Date Input",
  time: "Time Input",
  dropdown_select: "Single Select Dropdown",
  multiline_input: "Multi-line Text Input",
  multi_dropdown_select: "Multi-select Dropdown",
  radio_select: "Radio Selection",
  email: "Email",
  phone: "Phone",
  action: "Action Input",
  fixed: "Fixed Input",
  label: "label",
};

const SEPARATOR_MULTI_INPUT = "*";
const SEPARATOR_MULTI_INPUT_SUBHDR = "#";

const inputTableColumnTypes = {
  text: "Text Input",
  number: "Number Input",
  picture: "Photograph Upload",
  document: "Document Upload",
  dropDown: "Single Select Dropdown",
  date: "Date Input",
  button: "Button Action",
  location: "Location",
  fixedVal: "Fixed Value",
  invisibleDropdown: "Invisible Dropdown",
  time: "Time Input",
  preemptiveText: "Pre-Emptive Text",
  singlePreemptiveText: "Single Pre-Emptive Text",
  bulletPoints: "Bullet Points",
  imageCluster: "Image Cluster (Don't Select)",
};

interface TableMobDataStruct {
  id: string;
  columnid: string;
  // columnInputType?: string;
  // columnIdentifier?: string;
  // columnLabel?: string;
  // clickActionID?: ClickActionIDType;
  columnIdx: number;
  rowIdx: number;
  withName: boolean;
  highlight: boolean;
  doubleSize: boolean;
  fontSize: "large" | "small" | "medium";
  shortName: string;
  fontColor:
    | "primary"
    | "primary-dark"
    | "medium"
    | "secondary"
    | "tertiary"
    | "dark";
}

const mobileWidthBreakpoint = 480;
const tabWidthBreakpoint = 900;

interface FormRowType {
  rowNum: number;
  rowID: string;
  implementation: FormInputType[];
}

interface FormInputType {
  name: string;
  id: string;
  type: string;
  isHalfWidth: boolean;
  selectOptions: { id?: string; label: string }[];
  uniqueIdentifier: string;
  actionID?: string;
  dependencies?: string[];
  pullSql?: string;
  isMandatory: boolean;
  isSubmitArg: boolean;
  elNum: number;
  fixedVal?: string;
  sqlPush?: string;
}

interface FormValuesT {
  formTypeId: string;
  formInstanceId: string;
  isTransactional?: boolean;
  autoSubmit?: boolean;
  values: UniversalDataType;
}

interface FormSectionType {
  name: string;
  // For use in front end only. Back end should not touch
  sectionNum: number;
  secID: string;
  hideFromFiller: boolean;
  formInputRows: FormRowType[];
  // For expandable sections this is the sectionID of the first section in the group
  groupNumber?: number;
  subSections?: FormSectionType[];
  sectionReplcationFunc?: string;
}

const formActions = {
  saveForm: "Save Form",
  reviewForm: "Review Form",
  openFilledForm: "Open Filled Form",
  submitForm: "Submit Form",
};

const tableActions = {
  deleteTable: "Delete Table",
  editTable: "Edit Table",
  changeColSize: "Change Column Size",
};

interface SelectTableStructType {
  tableID: string;
  tableName?: string;
  params: {
    dependencyParams: string[];
    searchParams: {};
  };
}

interface FormDetailsType {
  schoolName: string;
  address: string;
  formName: string;
  session: string;
  formSections: FormSectionType[];
  notes: { id: string; note: string }[];
  deleteIdList?: string[];
  uidGenNum: number;
  pullSql?: string;
  onSubmitPath?: string;
  customSubmitRoute?: string;
  customFetchRoute?: string;
  replicationControl?: {
    pullSql: string;
    replicatorSqlVar: string[];
    replicationElements: string[];
  };
  actionTablePath: string;
  // Comma separated list of args to be present in the form url
  argDependencyList?: string;
}

interface SchoolDetailsStruct {
  name: string;
  address: string;
  session: string;
}

/**
 * Interface to open a generic popover to record data
 */
interface TableDataPopoverOpenStruct {
  isOpen: boolean;
  triggerID: string;
  colIdentifier: string;
  rowIdx: number;
  pcVerticalMode?: boolean;
}

/**
 * Interface to open a generic popover to filter data
 */
interface TableFilterPopoverOpenStruct {
  isOpen: boolean;
  triggerID: string;
  colIdentifier: string;
}

interface TableActionFormType {
  name: string;
  formID: string;
  isTransactional: boolean;
}

interface ActionIDFunctionStructure {
  (
    data: UniversalDataType[] | UniversalDataType,
    rowIdx: number | string,
    formStruct?: FormDetailsType,
    hooks?: any,
    sql?: { pull: string; push: string }
  ): any;
}

interface ActionsArray {
  [key: string]: ActionIDFunctionStructure;
}

interface UniversalDataElType {
  value: any;
  id: string;
  blob?: any;
  rotation?: number;
  backupVal?: any;
}

// Table data is an array with each index corresponding to a row having an object
// each row object has a key value pair of column identifier vs value
// for new rows added in edit mode there is an additional key 'isNew' with value of true to indicate this is a new row
// for existing rows which are edited will have a key 'isChanged'
// In table data to preserve the original rowID post filter a new key originalRowIdx is added in the filter data
interface UniversalDataType {
  [key: string]: UniversalDataElType;
}

interface ColumnSubSectionType {
  name: string;
  // Gpt prompt can be written with dependencies of values of other subsection as index.
  // Example if we have 3 subsections x,y and z and y has a dependency of x and z and the prompt needs to be
  // "Give me possible values of Y based on Z value of <Value of Z> and <value of X> number of Xs"
  // The prompt will be written as:
  // Assume the 3 sections as added as x y z meaning section x has index 0, y is 1 and z is 2
  // "Give me possible values of Y based on Z value of %%VAL%% and %%VAL%% number of Xs"
  // And index dependency is written as "2,0" with no spaces. The order in which the index is written is the value order in
  // which the markers %%VAL%% will be replaced.
  gptPrompt: string;
  promptIndexDep: string;
  placeHolderText: string;
}

interface ColumnStructure {
  id: string;
  uniqueIdentifier: string;
  label: string;
  // Type of the input
  type: string;
  // Can this column be filtered? The same values will be used for filter
  hasFilter: boolean;
  // If the column can be edited?
  canEdit: boolean;
  size: number;
  // Has an action.
  actionID?: string;
  // Has an click action.
  clickActionID?: ClickActionIDType;
  // function action for validity checker
  isValidID?: string;
  // For edit if the type is a dropdown
  // Values for validity checker
  values?: string[];
  // Sql query to pull the values
  valuesPullSql?: string;
  // Dependencies this column has on other columns identified with unique identifiers
  dependencies?: string[];
  // This defines while adding a new row if this row is mandatory to fill
  isMandatory: boolean;
  // Add subsections for bullet point mode only
  subSection?: ColumnSubSectionType[];
  pcVertColSize: number;
  placeholder: string;
  imgCropperRatio: number;
  implementation?: ColumnStructure[];
  hasPreFilter?: boolean;
  uniqueVal?: boolean;
}

interface DynDropdownValuesSqlPullStruct {
  elIdentifier: string;
  elID: string;
  sqlPull: string;
  depValues: { identifier: string; value: string }[];
}

interface DynDropdownValuesSqlPullResStruct {
  identifier: string;
  values: string[] | number[];
}

interface TableStructure {
  tableName: string;
  tableID: string;
  tableHelpText?: string;
  formTypeId?: string;
  columnStructure: ColumnStructure[];
  sqlPull?: string;
  sqlPush?: string;
  tblCbk?: string;
  uidGen: number;
  notExapndible?: boolean;
  isSchoolSpecific: boolean;
  actionForms?: TableActionFormType[];
  deleteSql?: string;
  deleteDependencies?: string[];
  clustering?: { groupName: string; uidList: string }[];
  tableLogo?: string;
}

interface ClickActionIDType {
  component:
    | "Form"
    | "Profile"
    | "Table"
    | "Calendar"
    | "Menu"
    | ""
    | "URL"
    | "Google Search";
  id: string;
  params: string[];
  customParams?: string[];
}

const TABLE_NUM_ROW_ADD = 15;
const LABEL_MAX_CHAR_BREAK_LIM = 30;

const onClickDestinationTypes = [
  "Form",
  "Profile",
  "Table",
  "Calendar",
  "Menu",
  "URL",
  "Google Search",
  "",
];

const onClickDestinationTypesPageMapping = {
  Dashboard: "landing-page?type=Table",
  Table: "/landing-page?type=Table",
  Form: "/forms?type=Form",
  Profile: "/profile?type=Form",
};

const getActionFunc = (strFromDB: string) => {
  // let str: string = `() => {const func = (tableData, rowID) => {console.log('execute form table data',tableData)}; return ( func )}`;
  return new Function("rowData", strFromDB);
};

// 0.5 for the index col size
const TABLE_GRID_SIZE = 12 - 0.25;
// 12 -1 for the index col
const TABLE_NUM_VISIBLE_COLS = 11;

const createDisplayFormArray = (
  formSectionsIn: FormSectionType[]
): FormSectionType[] => {
  const retVal: FormSectionType[] = [];
  for (const section of formSectionsIn) {
    if (keyExists("groupNumber", section)) {
      let sectionShell: FormSectionType = retVal.find(
        (item) => item.groupNumber === section.groupNumber
      );
      if (!sectionShell) {
        sectionShell = {
          name: section.name,
          sectionNum: -1,
          formInputRows: [],
          groupNumber: section.groupNumber,
          subSections: [],
          hideFromFiller: section.hideFromFiller,
          secID: "",
        };
        retVal.push(sectionShell);
      }
      sectionShell.subSections.push(section);
    } else {
      retVal.push(section);
    }
  }
  return retVal;
};

interface ActionButtonDB {
  title: string;
  exec: () => Promise<void> | void;
  isDisabled?: () => boolean;
  isVisible?: () => boolean;
}

const getSelectTableStructFromParams = (params): SelectTableStructType => {
  if (keyExists("id", params) || keyExists("tableName", params)) {
    const keys = Object.keys(params);
    const paramArray: string[] = [];
    const remParams = {};

    for (const key of keys) {
      if (key.indexOf("dependency_params") >= 0) {
        paramArray.push(params[key]);
      } else {
        remParams[key] = params[key];
      }
    }
    const retVal: SelectTableStructType = {
      tableID: params.id,
      tableName: params.tableName,
      params: {
        dependencyParams: paramArray,
        searchParams: remParams,
      },
    };
    return retVal;
  }
};

interface TableSelRowEditModeType {
  editMode: boolean;
  tableData: UniversalDataType[];
  rowIdx: number;
  setTableData: (inp: any) => void;
  tableStruct: TableStructure;
  refreshTable: () => void;
  closeTable: () => void;
}

const DROPDOWN_LIST_NUM_SEARCHBAR_LIM = 10;

export type {
  ActionButtonDB,
  FormInputType,
  FormRowType,
  FormSectionType,
  DynDropdownValuesSqlPullStruct,
  ClickActionIDType,
  DynDropdownValuesSqlPullResStruct,
  FormValuesT,
  SchoolDetailsStruct,
  FormDetailsType,
  ColumnStructure,
  ColumnSubSectionType,
  TableMobDataStruct,
  TableStructure,
  ActionIDFunctionStructure,
  ActionsArray,
  UniversalDataType,
  UniversalDataElType,
  SelectTableStructType,
  TableDataPopoverOpenStruct,
  TableFilterPopoverOpenStruct,
  TableActionFormType,
  TableSelRowEditModeType,
};
export {
  inputTypes,
  TABLE_GRID_SIZE,
  mobileWidthBreakpoint,
  tabWidthBreakpoint,
  inputTableColumnTypes,
  TABLE_NUM_ROW_ADD,
  TABLE_NUM_VISIBLE_COLS,
  LABEL_MAX_CHAR_BREAK_LIM,
  DROPDOWN_LIST_NUM_SEARCHBAR_LIM,
  SEPARATOR_MULTI_INPUT,
  SEPARATOR_MULTI_INPUT_SUBHDR,
  onClickDestinationTypes,
  tableActions,
  formActions,
  getActionFunc,
  createDisplayFormArray,
  getSelectTableStructFromParams,
  onClickDestinationTypesPageMapping,
};
